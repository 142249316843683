/* This CSS rule sets the box-sizing property to border-box for all elements and their before and after pseudo-elements in the application. 
The border-box value means that padding and borders are included in an element's total width and height.*/
*,
*::before,
*::after {
	box-sizing: border-box;
}

.accHeading {
	font-size: 20px;
	font-weight: 600;
	padding: 5px 10px 5px 0;
	border-bottom: 1px solid #000;
}

.formInnerSec {
	padding: 30px 0;
}
.GeneralSection {
	padding: 50px 10px;
}

/* This makes the popover visible when hovering or focusing on a label */
.tooltiphead:hover ~ app-popover .tooltip-text,
.tooltiphead:focus ~ app-popover .tooltip-text {
	visibility: visible;
}

/* Global styles for ngx-toastr toasts. This style each type of toast. */
.toast-title {
	background-position: 0% 50%;
	background-repeat: no-repeat;
	padding-left: 30px;
	background-size: 5.5%;
}

.toast-message {
	padding-left: 30px;
}

.toast-container .ngx-toastr {
	padding-left: 15px !important;
	color: black !important;
	box-shadow: 0 0 4px #999999 !important;
	background-color: white;
}

.toast-container .ngx-toastr:hover {
	box-shadow: 0 0 6px #999999 !important;
}

// Success toast
.toast-success .toast-title {
	background-image: url('../assets/images/bootstrap-icons/check-circle-fill.svg');
}

// Error toast
.toast-error .toast-title {
	background-image: url('../assets/images/bootstrap-icons/x-circle-fill.svg');
}

// Warning toast
.toast-warning .toast-title {
	background-image: url('../assets/images/bootstrap-icons/exclamation-triangle-fill.svg');
}

// Info toast
.toast-info .toast-title {
	background-image: url('../assets/images/bootstrap-icons/info-circle-fill.svg');
}
