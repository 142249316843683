/* Add global styles to this file, and also import other style files */

/* Importing overrides of bootstrap variables. */
@use '/src/styles/variables' as variables;
@use '/src/styles/custom' as *;

/* Importing Bootstrap SCSS files. */
@use 'bootstrap/scss/bootstrap' as bootstrap;
@use 'bootstrap-icons/font/bootstrap-icons.css' as *;

/* Importing regular stykes for ngx-toastr */
@use 'ngx-toastr/toastr' as *;

/* Global styles for the body of the webpage */
body {
	background-color: variables.$background-colour;
	font-family: variables.$font-family-base;
	font-size: variables.$font-size-base;
	font-weight: variables.$font-weight-normal;
	color: variables.$body-color;
}

body .p-overlay div {
	background-color: #f9f9f9;
	border-radius: 8px;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

body .p-overlay div div ul {
	padding-left: 0rem;
}

body .p-overlay div div ul li {
	padding: 0.3rem;
	&:hover {
		background-color: #eff6ff;
	}
}

body .p-overlay div div ul li.p-focus {
	padding: 0.3rem;
	background-color: #eff6ff;
}
