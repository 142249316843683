/* ----------------------------- BOOSTRAP OVERRIDES ----------------------------- */
$font-family-base: 'Inter';

$text-primary: #202327;
$body-color: #202327;

/* Colours*/
$primary-color: #326b8e;
$secondary-color: #baccd7;

$white: #fff;
$black: #000;
$error: #ff3333;
$warning: #ffcc00;
$success: #28a745;
$info: #17a2b8;

$smoke: #f8f8f8;
$dark-smoke: #f0f0f0;

$steel: #b8c6c9; //rgb(184, 198, 201)
$steel-light: #d4dddf; // rgb(212, 221, 223)
$steel-very-light: #eaeeef; // rgb(234, 238, 239)

$cadet-grey: #809298;

$dark-slate: #50635f;
$dark-slate-light: #b3bec1; // rgb(179, 190, 193).
$dark-slate-very-light: #cbd0cf; //rgb(203, 208, 207).
$dark-slate-extremely-light: #dce0df;

$link-blue: #0000ee;

$gunmetal: #202327;

/* Font Sizes */
$font-size-small: 0.875rem;
$font-size-base: 0.9rem;
$font-size-large: 1rem;
$font-size-h1: 1.5rem;
$font-size-h2: 1.2rem;
$font-size-h3: 1.1rem;

/* Font Weight */
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

/* Bootstrap grid */
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 1000px,
	lg: 1200px,
	xl: 1600px,
	xxl: 2000px
);

/*Link color*/
$link-color: #0d6efd;

/* Background Colour */
$background-colour: #f8f8f8;

/* Loading Spinner Colour */
$loading-spinner-colour: #f8f8f8;

/* Form Control Theme Settings*/
$border-colour: #cecece;
$border-focus-colour: #80bfff;
$border-hover-colour: #80bfff;
$disabled-colour: #cecece;
$disabled-colour-2: #a6a6a6;

/* Navigation Bar settings */
$navbar-subitem-background-color: #ebeaec;
$navbar-popup-background-color: #e5e7eb;
$navbar-subitem-text-color: #007e87;

/* Table Component */
$table-link-color: #007e87;
$table-border-color: #ccc;

/* ToggleSwitch Component */
$toggle-switch-active-color: #3b82f6;
$toggle-switch-inactive-color: #ced4da;

/*Table category divide colour*/
$table-category-divide: #dadada;

/* Ordering for components in the DOM for different screen sizes. */
@media (min-width: 1px) {
	@for $i from 1 through 50 {
		.app-order-#{$i} {
			order: $i;
		}
	}
}

@media (min-width: 1000px) {
	@for $i from 1 through 50 {
		.app-order-md-#{$i} {
			order: $i;
		}
	}
}

@media (min-width: 1200px) {
	@for $i from 1 through 50 {
		.app-order-lg-#{$i} {
			order: $i;
		}
	}
}

@media (min-width: 1600px) {
	@for $i from 1 through 50 {
		.app-order-xl-#{$i} {
			order: $i;
		}
	}
}

@media (min-width: 2000px) {
	@for $i from 1 through 50 {
		.app-order-xxl-#{$i} {
			order: $i;
		}
	}
}
